<script setup lang="ts">
import { useBreakpoints } from '@vueuse/core';
import Logo from '~/components/pro/atoms/Logo.vue';

import { Breakpoints, Breakpoint } from '~/config';

const breakpoints = useBreakpoints(Breakpoints);

</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel v-if="breakpoints.greaterOrEqual(Breakpoint.lg).value" :width="300" :resizable="{ min: 200, max: 300 }">
      <UDashboardNavbar :ui="{wrapper: 'h-auto border-b-0 py-4'}">
        <template #left>
          <div class="flex w-full items-center">
            <Logo class="text-gray-primary h-10 w-auto" text />
          </div>
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar :ui="{container:'py-4'}">
        <slot name="desktop-navigation" />

        <template v-if="!!$slots['desktop-actions']" #footer>
          <slot name="desktop-actions" />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <UDashboardPanel grow side="right" class="u-bg-secondary">
      <UDashboardPanelContent>
        <div v-if="breakpoints.smaller(Breakpoint.lg).value" class="flex w-full items-center justify-between">
          <Logo class="text-primary-500 h-10 w-auto" text />
          <UButton
            icon="i-heroicons-cog-6-tooth"
            color="gray"
          />
        </div>
        <slot name="header" />
        <slot />
      </UDashboardPanelContent>
      <template v-if="breakpoints.smaller(Breakpoint.lg).value">
        <slot name="mobile-navigation" />
      </template>
    </UDashboardPanel>
  </UDashboardLayout>
</template>
